import { useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useCuratedProperties } from '@features/properties/hooks/useCuratedProperties';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import PropertySlider from '@molecules/PropertySlider';
import { Neighbor } from '@services/hafh/types/generated';
import { pushDataLayer } from '@utils/spa-ga4';

type SectionLabel = {
  country_code: string;
  title: string;
};

const CuratedPropertiesContainer = () => {
  const { lang } = useTranslation();

  const [selectedGroupCode, setSelectedGroupCode] = useState<null | string>(
    null
  );

  const { curatedProperties } = useAppSelector((state) => ({
    curatedProperties: state.curatedProperties,
  }));

  const { neighbor }: { neighbor: Partial<Neighbor> } = useNeighbor();

  useCuratedProperties();

  const curationTitle = useMemo(() => {
    return (
      curatedProperties?.labels?.find(
        (label: SectionLabel) => label.country_code === lang
      )?.title || ''
    );
  }, [curatedProperties, lang]);

  const onCuratedPropertyGroupChange = (groupCode: string) => {
    setSelectedGroupCode(groupCode);

    // Push Group Click Event to GTM
    pushDataLayer({
      campaign_code: `hand-picked-properties_${curatedProperties?.id}`,
      click_id: groupCode,
      event: 'click_hand-picked-group',
      neighbor_id: neighbor?.id ?? '',
    });
  };

  const selectedGroupProperties = useMemo(() => {
    if (!curatedProperties?.groups?.length) {
      return [];
    }

    const selectedGroup = curatedProperties.groups.find(
      (group) => group.code === selectedGroupCode
    );

    if (!selectedGroup) {
      return curatedProperties.groups[0].properties;
    }

    return selectedGroup.properties;
  }, [curatedProperties, selectedGroupCode]);

  //Hide Section if no properties
  if (!curatedProperties?.labels?.length) {
    return null;
  }

  return (
    <PropertySlider
      campaignId={curatedProperties?.id}
      hasCuratedPropertyGroupTabs={true}
      name="curated-properties"
      properties={selectedGroupProperties}
      sliderType="curatedProperties"
      title={curationTitle}
      onCuratedPropertyGroupChange={onCuratedPropertyGroupChange}
    />
  );
};

export default CuratedPropertiesContainer;
