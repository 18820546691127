import CuratedPropertiesContainer from '@features/properties/containers/CuratedPropertiesContainer';
import PropertyHistoryContainer from '@features/properties/containers/PropertyHistoryContainer';
import ReservationCountRankingPropertiesContainer from '@features/properties/containers/ReservationCountRankingPropertiesContainer';
import styles from './css';

const PropertyLPSection = () => {
  return (
    <div className="property-section">
      <PropertyHistoryContainer />
      <CuratedPropertiesContainer />
      <ReservationCountRankingPropertiesContainer />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default PropertyLPSection;
