import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as reservationCountRankingActions from '@redux/modules/reservationCountRanking';
import { allRegion } from '@utils/constants';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';

export const useGetReservationCountRanking = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { getReservationCountRanking } = useActions({
    ...reservationCountRankingActions,
  });

  useEffect(() => {
    if (loadingAuthUser) {
      return;
    }

    getReservationCountRanking(locale, allRegion, authUser);
  }, [locale, loadingAuthUser]);
};
