import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as curatedPropertiesActions from '@redux/modules/curatedProperties';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';

export const useCuratedProperties = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };

  const { authUser, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
  }));

  const { getCuratedProperties } = useActions({
    ...curatedPropertiesActions,
  });

  useEffect(() => {
    if (loadingAuthUser) {
      return;
    }

    getCuratedProperties(locale, authUser);
  }, [locale, loadingAuthUser]);
};
